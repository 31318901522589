import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Auth from "../Auth/Auth";
import Main from "../Main/Main";
import Admin from "../AdminPanel/Main/Main";
import "../Main/Main.scss";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import NotFound from "../NotFound/NotFound";

export default function Routing() {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/admin" element={isAuth ? <Admin /> : <Auth /> }></Route>
          <Route path="/auth" element={<Auth /> }></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}
