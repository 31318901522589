import React, { useEffect, useState } from "react";
import { Table, Modal, Tag, Input, Form } from "antd";
import More from "./More";
import api from "../../ApiService/ApiService";
import "../styles.scss";
import { message } from "antd";

const Students = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dataMore, setDataMore] = useState({});
  const [dataDelete, setDataDelete] = useState({});
  const [data, setData] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const columns = [
    {
      title: "From",
      dataIndex: "nameZipCodeFrom",
      key: "nameZipCodeTo",
    },
    {
      title: "Where",
      dataIndex: "nameZipCodeTo",
      key: "nameZipCodeTo",
    },
    {
      title: "Type of car",
      dataIndex: "typeCar",
      key: "typeCar",
      render: (typeCar) => <span>{typeCar.name}</span>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <span>
          {status ? (
            <>
              <Tag color={"green"} key={"status"}>
                {"Success"}
              </Tag>
            </>
          ) : (
            <>
              <Tag color={"volcano"} key={"status"}>
                {"Waiting"}
              </Tag>
            </>
          )}
        </span>
      ),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, data) => (
        <div className="action_wrapp flex_row">
          <button className="primary_btn" onClick={() => openMore(data)}>
            More
          </button>
        </div>
      ),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, data) => (
        <div className="action_wrapp flex_row">
          <button
            disabled={data.status}
            className="primary_btn"
            onClick={() => closeOrder(data)}
          >
            Close
          </button>
        </div>
      ),
    },
  ];

  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgWarning = (m) => {
    messageApi.open({
      type: "warning",
      content: m,
    });
  };
  const msgSuccess = (m) => {
    messageApi.open({
      type: "success",
      content: m,
    });
  };
  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    var t = await api.getOrders();
    setData(t);
  };

  const closeOrder = async (data) => {
    if (!data.status) {
      let formData = new FormData();
      formData.append("id", data.id);
      let r = await api.editOrders(formData);
      msgSuccess("The order is closed");
      getOrders();
    } else {
      msgWarning("The order is already closed");
    }
  };

  const handleCancel = () => {
    setShowDeleteModal(false);
  };

  const handleOk = () => {
    setShowDeleteModal(false);
  };

  const openMore = (data) => {
    setShowMoreModal(true);
    setDataMore(data);
  };
  const openDelete = (data) => {
    setShowDeleteModal(true);
    setDataDelete(data);
  };

  return (
    <div className="students_wrapp">
      {contextHolder}
      <Table columns={columns} dataSource={data} />
      {showMoreModal && (
        <>
          <More
            data={dataMore}
            open={showMoreModal}
            setOpen={setShowMoreModal}
          />
        </>
      )}
      <Modal
        open={showDeleteModal}
        title="Delete request"
        onCancel={handleCancel}
        onOk={handleOk}
        footer={[
          <>
            <button className="danger_btn" onClick={handleOk}>
              Delete
            </button>
            <button className="primary_btn_outline" onClick={handleCancel}>
              Close
            </button>
          </>,
        ]}
      >
        <span>Do you really want to delete the data permanently?</span>
      </Modal>
    </div>
  );
};
export default Students;
