import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Input, Form } from "antd";
import api from "../../ApiService/ApiService";

const Edit = ({ data, open, setOpen, get }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [countMax, setCountMax] = useState("");
  const [countMin, setCountMin] = useState("");

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ name: data.name });
      form.setFieldsValue({ countMin: data.countMin });
      form.setFieldsValue({ countMax: data.countMax });
      setName(data.name);
    }
  }, [data]);

  const editCar = async (d) => {
    let formData = new FormData();
    formData.append("id", data.id);
    formData.append("name", d.name);
    formData.append("countMax", d.countMax);
    formData.append("countMin", d.countMin);
    let r = await api.editCar(formData);
    get();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title="Изменение категории"
        onCancel={handleCancel}
        footer={[<></>]}
      >
        <div>
          <div className="name_wrapp">
            <Form
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={(data) => editCar(data)}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Car"
                rules={[
                  {
                    required: true,
                    message: "The field cannot be empty",
                  },
                ]}
              >
                <Input onInput={(data) => setName(data.target.value)} />
              </Form.Item>
              <Form.Item
                name="countMin"
                label="Count Min"
                rules={[
                  {
                    required: true,
                    message: "The field cannot be empty",
                  },
                ]}
              >
                <Input onInput={(data) => setCountMin(data.target.value)} />
              </Form.Item>
              <Form.Item
                name="countMax"
                label="Count Max"
                rules={[
                  {
                    required: true,
                    message: "The field cannot be empty",
                  },
                ]}
              >
                <Input onInput={(data) => setCountMax(data.target.value)} />
              </Form.Item>
              <Form.Item className="flex_end">
                <input
                  className="primary_btn right"
                  type="submit"
                  value="Edit"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Edit;
