import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Input, Form } from "antd";
import api from "../../ApiService/ApiService";

const Edit = ({ data, open, setOpen, get }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ name: data.name });
      form.setFieldsValue({ location: data.location });
      setName(data.name);
      setLocation(data.location);
    }
  }, [data]);

  const editCategory = async (d) => {
    let formData = new FormData();
    data.name = name;
    data.location = location;
    formData.append("name", data.name);
    formData.append("location", data.location);
    formData.append("id", data.id);
    var t = await api.editCity(formData);
    setOpen(false);
    get();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title="Edit city"
        onCancel={handleCancel}
        footer={[<></>]}
      >
        <div>
          <div className="name_wrapp">
            <Form
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={(data) => editCategory(data)}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "The field cannot be empty",
                  },
                ]}
              >
                <Input onInput={(data) => setName(data.target.value)} />
              </Form.Item>
              <Form.Item
                name="location"
                label="Location"
                rules={[
                  {
                    required: true,
                    message: "The field cannot be empty",
                  },
                ]}
              >
                <Input onInput={(data) => setLocation(data.target.value)} />
              </Form.Item>
              <Form.Item className="flex_end">
                <input
                  className="primary_btn right"
                  type="submit"
                  value="Edit"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Edit;
