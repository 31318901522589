import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Input, Form, Collapse } from "antd";
import Edit from "./Edit";
import Add from "./Add";
import api from "../../ApiService/ApiService";
import Cookie from "../../../store/cookie";

const Category = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [cities, setCities] = useState([]);
  const [dataDelete, setDataDelete] = useState({});
  const cookie = new Cookie();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, data) => (
        <>
          {cookie.getCookie("TransportationUserRole") == "Admin" && (
            <div className="action_wrapp flex_row">
              <button className="primary_btn" onClick={() => openEdit(data)}>
                Edit
              </button>
              <button className="danger_btn" onClick={() => openDelete(data)}>
                Delete
              </button>
            </div>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getCities();
  }, []);

  const handleCancel = () => {
    setShowDeleteModal(false);
  };

  const handleOk = async () => {
    let formData = new FormData();
    formData.append("idCity", dataDelete.id);
    let r = await api.deleteCity(formData);
    setShowDeleteModal(false);
    getCities();
  };

  const getCities = async () => {
    var t = await api.getCities();
    if (t.cities) {
      setCities(t.cities);
    }
  };

  const openEdit = (data) => {
    setShowEditModal(true);
    setDataEdit(data);
  };
  const openDelete = (data) => {
    setShowDeleteModal(true);
    setDataDelete(data);
  };

  return (
    <div className="students_wrapp">
      {cookie.getCookie("TransportationUserRole") == "Admin" && (
        <>
          <button
            className="primary_btn w100"
            onClick={() => setShowAddModal(true)}
          >
            + Add
          </button>
        </>
      )}
      <Table columns={columns} dataSource={cities} />
      {showEditModal &&
        cookie.getCookie("TransportationUserRole") == "Admin" && (
          <>
            <Edit
              data={dataEdit}
              open={showEditModal}
              setOpen={setShowEditModal}
              get={getCities}
            />
          </>
        )}
      {showAddModal &&
        cookie.getCookie("TransportationUserRole") == "Admin" && (
          <>
            <Add
              open={showAddModal}
              setOpen={setShowAddModal}
              get={getCities}
            />
          </>
        )}
      <Modal
        open={showDeleteModal}
        title="Delete city"
        onCancel={handleCancel}
        onOk={handleOk}
        footer={[
          <>
            <button className="danger_btn" onClick={handleOk}>
              Delete
            </button>
            <button className="primary_btn_outline" onClick={handleCancel}>
              Close
            </button>
          </>,
        ]}
      >
        <span>Are you sure?</span>
      </Modal>
    </div>
  );
};
export default Category;
