import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Input, Form, Collapse } from "antd";
import Edit from "./Edit";
import Add from "./Add";
import api from "../../ApiService/ApiService";
import Cookie from "../../../store/cookie";

const Length = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [length, setLength] = useState([]);
  const [dataDelete, setDataDelete] = useState({});
  const cookie = new Cookie();
  const columns = [
    {
      title: "Lenght from",
      dataIndex: "lengthFrom",
      key: "lengthFrom",
    },
    {
      title: "Lenght to",
      dataIndex: "lengthTo",
      key: "lengthTo",
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_, data) => (
        <>
            <div className="action_wrapp flex_row">
              <button className="primary_btn" onClick={() => openEdit(data)}>
                Edit
              </button>
              <button className="danger_btn" onClick={() => openDelete(data)}>
                Delete
              </button>
            </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    getLength();
  }, []);

  const handleCancel = () => {
    setShowDeleteModal(false);
  };

  const handleOk = async () => {
    let formData = new FormData();
    formData.append("Id", dataDelete.id);
    let r = await api.deleteLength(formData);
    setShowDeleteModal(false);
    getLength();
  };

  const getLength = async () => {
    var t = await api.getLength();
    if (t.types) {
      setLength(t.types);
    }
  };

  const openEdit = (data) => {
    setShowEditModal(true);
    setDataEdit(data);
  };
  const openDelete = (data) => {
    setShowDeleteModal(true);
    setDataDelete(data);
  };

  return (
    <div className="students_wrapp">
      {cookie.getCookie("TransportationUserRole") == "Admin" && (
        <>
          <button
            className="primary_btn w100"
            onClick={() => setShowAddModal(true)}
          >
            + Add
          </button>
        </>
      )}
      <Table columns={columns} dataSource={length} />
      {showEditModal &&
        cookie.getCookie("TransportationUserRole") == "Admin" && (
          <>
            <Edit
              data={dataEdit}
              open={showEditModal}
              setOpen={setShowEditModal}
              get={getLength}
            />
          </>
        )}
      {showAddModal &&
        cookie.getCookie("TransportationUserRole") == "Admin" && (
          <>
            <Add
              open={showAddModal}
              setOpen={setShowAddModal}
              get={getLength}
            />
          </>
        )}
      <Modal
        open={showDeleteModal}
        title="Delete length"
        onCancel={handleCancel}
        onOk={handleOk}
        footer={[
          <>
            <button className="danger_btn" onClick={handleOk}>
              Delete
            </button>
            <button className="primary_btn_outline" onClick={handleCancel}>
              Close
            </button>
          </>,
        ]}
      >
        <span>Are you sure?</span>
      </Modal>
    </div>
  );
};
export default Length;