import "./App.css";
import "./components/Adaptive.scss";
import Routing from "./components/Routing";
import { Provider } from "react-redux";
import Main from "./components/Main/Main";
import store from "./store/store";
import theme from "./components/Theme/Theme";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider theme={theme}>
    <Provider store={store}>
      <Routing />
    </Provider>
    </ConfigProvider>
  );
}

export default App;
