class Cookie {
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    } 

    setCookie(cname, cvalue, expire) {
        if(!expire){
            expire = new Date();
            expire.setDate(expire.getDate() + 7);
        }
        document.cookie = cname + "=" + cvalue + ";path=/;Expires=" + expire + ";";
    }

    deleteCookie(cname){
        document.cookie = cname + "=;path=/;Expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    }
}

export default Cookie;