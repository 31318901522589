import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Input, Form } from "antd";
import api from "../../ApiService/ApiService";

const Add = ({ open, setOpen, get }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [countMax, setCountMax] = useState("");
  const [countMin, setCountMin] = useState("");

  useEffect(() => {
    form.resetFields();
    setName("");
    setCountMax("");
    setCountMin("");
  }, []);

  const AddCar = async (data) => {
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("countMax", data.countMax);
    formData.append("countMin", data.countMin);
    let r = await api.createCar(formData);
    setOpen(false);
    get();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title="New car"
        onCancel={handleCancel}
        footer={[<></>]}
      >
        <div>
          <div className="name_wrapp">
            <Form
              form={form}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={(data) => AddCar(data)}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Car"
                rules={[
                  {
                    required: true,
                    message: "The field cannot be empty",
                  },
                ]}
              >
                <Input onInput={(data) => setName(data.target.value)} />
              </Form.Item>
              <Form.Item
                name="countMin"
                label="Count Min"
                rules={[
                  {
                    required: true,
                    message: "The field cannot be empty",
                  },
                ]}
              >
                <Input onInput={(data) => setCountMin(data.target.value)} />
              </Form.Item>
              <Form.Item
                name="countMax"
                label="Count Max"
                rules={[
                  {
                    required: true,
                    message: "The field cannot be empty",
                  },
                ]}
              >
                <Input onInput={(data) => setCountMax(data.target.value)} />
              </Form.Item>
              <Form.Item className="flex_end">
                <input className="primary_btn" type="submit" value="Add" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Add;
