import React, { useState } from "react";
import logo_mob from "../../media/images/logo-circle1200.webp";
import logo from "../../media/images/logo1200.webp";
import "./Header.scss";

const Header = ({ switchMenu, showMobileMenu }) => {
  const Anchor = (id) => {
    try {
      switchMenu(true);
      document.body.style.overflow = "auto";
      let e = document.getElementById(id);
      if (e) {
        window.scrollTo({
          top: e.offsetTop,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="header_wrapp" className="wrapper flex_col flex_btw">
      <nav class="nav">
        <div class="mobile_logo">
          <a href="/">
            <img className="logo" loading="lazy" width="100%" height="100%"  src={logo_mob  } />
          </a>
        </div>
        <input
          type="checkbox"
          id="nav-check"
          checked={showMobileMenu}
          onClick={() => {
            switchMenu(showMobileMenu);
          }}
        />
        <div class="nav-btn">
          <label for="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <ul class="nav-list">
          <li onClick={() => Anchor("root")}>Home</li>
          <li onClick={() => Anchor("getstarted")}>Get started</li>
          <div class="nav-title">
            <a href="/">
              <img className="logo" loading="lazy"  width= "100%" height="100%" src={logo} />
            </a>
          </div>
          <li onClick={() => Anchor("pricetable")}>Price table</li>
          <li onClick={() => Anchor("footer_wrapp")}>Contacts</li>
        </ul>
      </nav>
      <div className="container">
        <div className="banner_content">
          <h2>KG TRUCKERS</h2>
          <h3>
            Makes it easy to have
            <br /> your car delivered
          </h3>
          <div
            className="button"
            onClick={() => {
              Anchor("getstarted");
            }}
          >
            Get started
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
