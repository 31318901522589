import { createSlice } from "@reduxjs/toolkit";
import Cookie from "../store/cookie";
import jwtDecode from "jwt-decode";
let cookie = new Cookie();
const initialState = {
  isAuth: cookie.getCookie("TransportationUserToken"),
  username: cookie.getCookie("TransportationUserName"),
  role: cookie.getCookie("TransportationUserRole"),
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn: (state, action) => {
      const decodeToken = jwtDecode(action.payload.token)
      state.isAuth = true;
      state.token = action.payload.token;
      state.role = decodeToken.role;
      state.username = decodeToken.name;
      let expire = new Date();
      expire.setDate(expire.getDate() + 7);
      cookie.setCookie("TransportationUserToken", action.payload.token, expire);
      cookie.setCookie("TransportationUserRole", decodeToken.role, expire);
      cookie.setCookie("TransportationUserName", decodeToken.name, expire);
    },
    logOut: (state) => {
      state.isAuth = false;
      state.login = "";
      state.token = "";
      state.username = "";

      cookie.deleteCookie("TransportationUserToken");
      cookie.deleteCookie("TransportationUserRole");
      cookie.deleteCookie("TransportationUserName");
    },

    turnLoad: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logIn, logOut, turnLoad } = authSlice.actions;

export default authSlice.reducer;
