import axios from "axios";
import Settings from "../../Settings";
import { useDispatch } from "react-redux";
import Cookie from "../../store/cookie";

const cookie = new Cookie();
const headers = {
  Authorization: `Bearer ${cookie.getCookie("TransportationUserToken")}`,
};

export default class ApiService {
  static auth = async (data) => {
    try {
      const response = await axios.post(Settings.ServerURL + "/auth", data);
      return response.data;
    } catch (error) {}
  };

  static getOrders = async () => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/getApplication",
        "",
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  static editOrders = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/editApplication",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static getCities = async () => {
    try {
      const response = await axios.get(Settings.ServerURL + "/getCity");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static editCity = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/editCity",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  static createCity = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/createCity",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static deleteCity = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/deleteCity",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static createType = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/createType",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static editType = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/editType",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  static deleteType = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/deleteType",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  static createCar = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/createCar",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static editCar = async (data) => {
    try {
      const response = await axios.post(Settings.ServerURL + "/editCar", data, {
        headers: {
          Authorization: `Bearer ${cookie.getCookie(
            "TransportationUserToken"
          )}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  static deleteCar = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/deleteCar",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static getPostalCodes = async (c) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/getPostalcode",
        c
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static getPrice = async (f) => {
    try {
      const response = await axios.post(Settings.ServerURL + "/price", f);
      return response.data;

    } catch (error) {
      console.log(error);
    }
  };

  static getTypes = async () => {
    try {
      const response = await axios.get(Settings.ServerURL + "/getTypes");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static getCars = async () => {
    try {
      const response = await axios.get(Settings.ServerURL + "/getCars");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  static getLength = async () => {
    try {
      const response = await axios.get(Settings.ServerURL + "/getLength", {
        headers: {
          Authorization: `Bearer ${cookie.getCookie(
            "TransportationUserToken"
          )}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  static editLength = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/editLength",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  static createLength = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/createLength",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data.type;
    } catch (error) {
      console.log(error);
    }
  };
  static deleteLength = async (data) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/deleteLength",
        data,
        {
          headers: {
            Authorization: `Bearer ${cookie.getCookie(
              "TransportationUserToken"
            )}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static sendContacts = async (f) => {
    try {
      const response = await axios.post(
        Settings.ServerURL + "/createApplication",
        f
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}
