import React from "react";
import notFound from "../../media/images/AdminPanel/404.svg";
import "./notfound.scss";

const NotFound = () => {
  return (
    <div className="notFound_wrapp">
      <div className="flex_center flex_col">
        <img src={notFound} />
        <h4> OOPS...PAGE NOT FOUND</h4>
      </div>
    </div>
  );
};
export default NotFound;
