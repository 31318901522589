import React from "react";
import Requests from "../Orders/Orders";
import "./Main.scss";
import { Collapse } from "antd";
import Cities from "../Cities/Cities";
import Type from "../Type/Type";
import Cars from "../Cars/Cars";
import Length from "../Length/Length";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { logOut } from "../../../reducers/authSlice";
import { LogoutOutlined } from "@ant-design/icons";
import Cookie from "../../../store/cookie";

const Main = () => {
  const dispatch = useDispatch();
  const cookie = new Cookie()

  const logoutFun = () => {
    dispatch(logOut());
  };
  return (
    <>
      <div className={"flex_center flex_col"}>
        <div className={"main_wrapp"}>
          <div className="flex_end">
            <button className="danger_btn" onClick={logoutFun}>
              Log out <LogoutOutlined />
            </button>
          </div>
          <div className={"collapse_wrapp"}>
            <Collapse
              items={[{ key: "1", label: "Requests", children: <Requests /> }]}
              defaultActiveKey={["1"]}
            />
          </div>
          <div className={"collapse_wrapp"}>
            <Collapse
              items={[{ key: "2", label: "Cities", children: <Cities /> }]}
            />
          </div>
          <div className={"collapse_wrapp"}>
            <Collapse
              items={[
                {
                  key: "3",
                  label: "Type of transportation",
                  children: <Type />,
                },
              ]}
            />
          </div>
          <div className={"collapse_wrapp"}>
            <Collapse
              items={[{ key: "4", label: "Cars", children: <Cars /> }]}
            />
          </div>
          {cookie.getCookie("TransportationUserRole") == "Admin" &&  <>
          <div className={"collapse_wrapp"}>
            <Collapse
              items={[{ key: "5", label: "Length", children: <Length /> }]}
            />
          </div>
          </>}
        </div>
      </div>
    </>
  );
};

export default Main;
