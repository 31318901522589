import React from "react";
import "./Loader.scss";

const Loader = ({show}) => {
  return (
    <div className={`loader_bg flex_center ${show ? "loader_show" : "loader_hidden"}`}>
      <div className="loader_wrapp">
        <div className="loader">

        </div>
      </div>
    </div>
  );
};

export default Loader;
