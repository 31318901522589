import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Input, Form } from "antd";

const More = ({ data, open, setOpen }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [login, setLogin] = useState("");

  useEffect(() => {
    if (data.name) {
      form.setFieldsValue({ login: data.login });
      form.setFieldsValue({ name: data.name.split(" ")[0] });
      form.setFieldsValue({ surname: data.name.split(" ")[1] });
      setName(data.name.split(" ")[0]);
      setSurName(data.name.split(" ")[1]);
      setLogin(data.login);
    }
  }, [data]);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} title="" onCancel={handleCancel} footer={[<></>]}>
        <div className="more_body">
          <span className="title_medium">From</span>
          <div className="section flex_row w100">
            <div className="w50">
              <div>
                <b>City</b> : <span>{data.cityFrom.name}</span>
              </div>
              <div>
                <b>location</b> : <span>{data.cityFrom.location}</span>
              </div>
            </div>
            <div>
              <div>
                <b>Zip Code</b> : <span>{data.nameZipCodeFrom}</span>
              </div>
              <div>
                <b>Zip Code</b> : <span>{data.locationZipCodeFrom}</span>
              </div>
            </div>
          </div>
          <span className="title_medium">To</span>
          <div className="section flex_row w100">
            <div className="w50">
              <div>
                <b>City</b> : <span>{data.cityTo.name}</span>
              </div>
              <div>
                <b>location</b> : <span>{data.cityTo.location}</span>
              </div>
            </div>
            <div>
              <div>
                <b>Zip Code</b> : <span>{data.nameZipCodeTo}</span>
              </div>
              <div>
                <b>Zip Code</b> : <span>{data.locationZipCodeTo}</span>
              </div>
            </div>
          </div>
          <span className="title_medium">Car</span>
          <div className="section flex_row w100">
            <div className="w50">
              <div>
                <b>Model</b> : <span>{data.car.name}</span>
              </div>
              <div>
                <b>Type</b> : <span>{data.typeCar.name}</span>
              </div>
            </div>
            <div>
              <div>
                <b>Count</b> : <span>{data.car.count}</span>
              </div>
              <div>
                <b>Count</b> : <span>{data.typeCar.count}</span>
              </div>
            </div>
          </div>
          <span className="title_medium">Client info</span>
          <div className="section flex_row  w100">
            <div className="w50">
              <div>
                <b>Name</b> : <span>{data.nameClient}</span>
              </div>
              <div>
                <b>Phone</b> : <span>{data.phoneClient}</span>
              </div>
            </div>
            <div>
              <div>
                <b>Count</b> : <span>{data.car.count}</span>
              </div>
              <div>
                <b>Count</b> : <span>{data.typeCar.count}</span>
              </div>
            </div>
          </div>
          <span className="title_medium">Path info</span>
          <div className="section flex_row  w100">
            <div className="w50">
              <div className="flex_col">
                <span>
                  <b>Length : </b>{Math.floor(data.length)} mi
                </span>
                <span>
                  <b>Desire data : </b> {new Date(data.desiredDate).toLocaleString()}
                </span>
              </div>
            </div>
            <div>
              <div>
                <b>price</b> : <span>$ {Math.floor(data.price)}</span>
              </div>
            </div>
          </div>
          <span className="title_medium">Order info</span>
          <div className="section flex_row w100">
            <div>
              <div>
                <b>Create date</b> : <span>{new Date(data.createDate).toLocaleString()}</span>
              </div>
            </div>
          </div>
          <span className="title_medium">Status</span>
          <div className="section flex_row w100">
            <div>
              <div>
                <b>Status</b> :{" "}
                <span>
                  {data.status ? (
                    <span style={{ color: "green" }}>Success</span>
                  ) : (
                    <span style={{ color: "red" }}>Waiting</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default More;
