import React, { useState } from "react";
import "./Auth.scss";
import { useDispatch } from "react-redux";
import { logIn, turnLoad } from "../../reducers/authSlice";
import { Input, message } from "antd";
import ApiService from "../ApiService/ApiService";
import Cookie from "../../store/cookie";
import authImg from '../../media/images/AdminPanel/auth.svg'

const Auth = () => {
  const dispatch = useDispatch();

  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();

  const cookie = new Cookie();
  const msgError = (m) => {
    messageApi.open({
      type: "error",
      content: m,
    });
  };
  const msgWarning = (m) => {
    messageApi.open({
      type: "warning",
      content: m,
    });
  };
  const tryAuth = async () => {
    dispatch(turnLoad(true));
    try {
      if (login && password) {
        let letsAuth = {
          userName: login,
          password: password,
          role: "",
        };
        let r = await ApiService.auth(letsAuth);
        dispatch(logIn(r));
      } else {
        msgWarning("Введите логин и пароль!");
      }
    } catch (error) {
      msgWarning("Неверный логин или пароль");
      //console.log(error);
    }

    setTimeout(() => {
      dispatch(turnLoad(false));
    }, 1000);
  };

  return (
    <div className="auth_wrapp flex_col">
      {contextHolder}
      <div className="flex_center">
        <img src={authImg}/>
      </div>
      <div className="auth card flex_col">
        <h5>Sign In</h5>
        <Input
          placeholder="login"
          value={login}
          required
          onInput={(e) => {
            setLogin(e.target.value);
          }}
        ></Input>
        <Input
          placeholder="password"
          required
          type="password"
          onInput={(e) => {
            setPassword(e.target.value);
          }}
        ></Input>
        <center>
          <button
            onClick={tryAuth}
            className="primary_btn"
            onPress
          >
            Войти
          </button>
        </center>
      </div>
    </div>
  );
};

export default Auth;
