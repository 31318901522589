import React, { useEffect, useState } from "react";
import "./Content.scss";
import cardImg1 from "../../media/images/always transparent582.jpg";
import cardImg2 from "../../media/images/best car shipping582.jpg";
import cardImg3 from "../../media/images/upfront pricing.webp";
import arrows from "../../media/images/icons/arrows.webp";
import statistic from "../../media/images/we work hard600.webp";
import location from "../../media/images/icons/Point.webp";
import mail from "../../media/images/icons/Mail.webp";
import phone from "../../media/images/icons/Phone.webp";
import fb from "../../media/images/icons/fb.webp";
import inst from "../../media/images/icons/inst.webp";
import tw from "../../media/images/icons/tw.webp";
import tg from "../../media/images/icons/tg.webp";
import { Select } from "antd";
import ApiService from "../ApiService/ApiService";
import "./Steps.scss";
import shipment from "../../media/images/shipment1200.webp";
import Loader from "../Loader/Loader";
import arrowBack from "../../media/images/icons/arrow.webp";
import { Modal } from "antd";
import success from "../../media/images/icons/success.webp";
import warning from "../../media/images/icons/warning.webp";
import logo from "../../media/images/logo_white1200.webp";
import { notification } from "antd";
import Constants from "../../Constants.json";

const Content = ({ switchMenu }) => {
  const [api, contextHolder] = notification.useNotification();

  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");

  const [cities, setCities] = useState([]);
  const [cityTo, setCityTo] = useState("...");
  const [cityFrom, setCityFrom] = useState("...");

  const [methodTypes, setMethodTypes] = useState([]);
  const [method, setMethod] = useState({ name: "Open" });

  const [ZIPFromOptions, setZIPFromOptions] = useState([]);
  const [ZIPToOptions, setZIPToOptions] = useState([]);
  const [ZIPTo, setZIPTo] = useState(null);
  const [ZIPFrom, setZIPFrom] = useState(null);

  const [cars, setCars] = useState([]);
  const [car, setCar] = useState(null);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalRoad, setTotalRoad] = useState(0);

  const [loadCalculate, setLoadCalculate] = useState(false);
  const [loadCities, setLoadCities] = useState(false);
  const [loadSendMsg, setLoadSendMsg] = useState(false);
  const [loadCars, setLoadCars] = useState(false);
  const [loadZIP, setLoadZIP] = useState(false);

  const [showResult, setShowResult] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openNotification = (msg) => {
    let placement = "topRight";

    api.info({
      icon: (
        <div className="notification_icon flex_center h100">
          <img src={warning} loading="lazy" width="100%" height="100%"/>
        </div>
      ),
      message: `Warning`,
      description: msg,
      placement,
    });
  };

  const getCities = async () => {
    setLoadCities(true);
    try {
      let c = await ApiService.getCities();
      if (c.cities) {
        let ops = [];
        c.cities.map((i) => {
          ops.push({
            label: i.name,
            value: i.id,
          });
        });

        setCities(ops);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadCities(false);
  };

  const handleChangeCity = async (city, s) => {
    setLoadZIP(true);
    try {
      setZIPFrom(null);
      setZIPTo(null);
      let f = new FormData();
      f.append("city", city.label);
      let c = await ApiService.getPostalCodes(f);
      if (c) {
        let ops = [];
        c.map((i, j) => {
          ops.push({
            label: i.name,
            value: j,
            location: i.location,
          });
        });
        if (s === "from") {
          setZIPFromOptions(ops);
          setCityFrom(city);
        } else {
          setZIPToOptions(ops);
          setCityTo(city);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoadZIP(false);
  };

  const getCars = async () => {
    setLoadCars(true);
    try {
      let c = await ApiService.getCars();
      if (c.responseCode == 0) {
        let ops = [];
        c.cars.map((i) => {
          ops.push({
            label: i.name,
            value: i.id,
            min: i.countMin,
            max: i.countMax,
          });
        });
        setCars(ops);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadCars(false);
  };

  const getMethods = async () => {
    try {
      let c = await ApiService.getTypes();
      if (c.responseCode == 0) {
        setMethodTypes(c.types);
        if (c.types.length) {
          setMethod(c.types[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCar = (car) => {
    if (car) {
      setCar(car);
    }
  };

  const calculate = async () => {
    setLoadCalculate(true);
    try {
      if (!cityFrom || !cityTo) {
        openNotification("Please choose the city.");
      } else if (!car) {
        openNotification("Please select size of car.");
      } else {
        let f = new FormData();
        f.append("CityTo.Location", ZIPTo.location);
        f.append("CityFrom.Location", ZIPFrom.location);
        f.append("Car.CountMin", car.min);
        f.append("Car.CountMax", car.max);
        f.append("TypeCar.Count", method.count);
        let c = await ApiService.getPrice(f);
        if (c) {
          setTotalRoad(c.length);
          setTotalPrice(c.price);
          setShowResult(true);
        }
      }
    } catch (error) {
      openNotification(
        "Something gone wrong... Check the correctness of the entered data or try later"
      );
      console.log(error);
    }
    setLoadCalculate(false);
  };

  const Anchor = (id) => {
    try {
      switchMenu(true);
      document.body.style.overflow = "auto";
      let e = document.getElementById(id);
      if (e) {
        window.scrollTo({
          top: e.offsetTop,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCities();
    getCars();
    getMethods();
  }, []);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const sendContacts = async () => {
    setLoadSendMsg(true);
    try {
      if (clientName && clientPhone) {
        let f = new FormData();
        f.append("NameClient", clientName);
        f.append("PhoneClient", clientPhone);
        f.append("Price", totalPrice);
        f.append("Length", totalRoad);
        f.append("IdTypeCar", method.id);
        f.append("IdCar", car.value);
        f.append("Car.Name", car.label);
        f.append("Car.CountMin", car.min);
        f.append("Car.CountMax", car.max);
        f.append("LocationZipCodeFrom", ZIPFrom.location);
        f.append("NameZipCodeFrom", ZIPFrom.label);
        f.append("LocationZipCodeTo", ZIPTo.location);
        f.append("NameZipCodeTo", ZIPTo.label);
        f.append("IdCityFrom", cityFrom.value);
        f.append("IdCityTo", cityTo.value);

        let r = await ApiService.sendContacts(f);
        if (r.responseCode == "0") {
          handleOpenModal(true);
          Anchor("step3");
        } else {
          throw "error";
        }
      } else {
        openNotification("Please fill your contacts");
      }
    } catch (error) {
      openNotification(
        "Something gone wrong... Check the correctness of the entered data or try later"
      );
      console.log(error);
    }
    setTimeout(setLoadSendMsg, 1000, false);
  };

  return (
    <>
      {contextHolder}
      <Modal open={showModal} onCancel={handleCloseModal} footer={[]}>
        <div className="msg_wrapp flex_vcenter">
          <img src={success} />
          <span
            dangerouslySetInnerHTML={{
              __html: Constants.StepsDescription.successMsg,
            }}
          ></span>
        </div>
      </Modal>
      <div className="wrapp_title flex_col flex_center">
        <h5>Car Transport Service</h5>
        <h3>How we can help?</h3>
      </div>
      <div id="service_wrapp" className="wrapper">
        <div className="container">
          <div className="cards_wrapp flex_stretch flex_btw">
            <div className="card flex_col flex_btw">
              <div className="img">
                <img src={cardImg1} loading="lazy" width="100%" height="100%" />
              </div>
              <h4>Transparency</h4>
              <div className="content">
                We're always transparent about the mitigating factors that
                affect your shipping price for a given order.
              </div>
              <span>...</span>
            </div>

            <div className="card flex_col flex_btw">
              <div>
                <div className="img">
                  <img src={cardImg2} loading="lazy" width="100%" height="100%" />
                </div>
                <h4>Experience</h4>
                <div className="content">
                  In our minds, that's the approach the very best of the best
                  car shipping companies should take.
                </div>
              </div>
              <span>...</span>
            </div>

            <div className="card flex_col flex_btw">
              <div className="img">
                <img src={cardImg3} loading="lazy" width="100%" height="100%" />
              </div>
              <h4>Affordability</h4>
              <div className="content">
                You should always expect upfront pricing from the best car
                shipping companies in the industry
              </div>
              <span>...</span>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapp_title flex_col flex_center" id="getstarted">
        <h5>Get started</h5>
        <h3>Auto shipment in 3 simple stages</h3>
      </div>
      <div id="route_wrapp" className="wrapper flex_col">
        <div className="step_wrapp flex_row" id="step1">
          <div className="w50 flex_center flex_col">
            <span className="step_head">Stage 1</span>
            <span>
              <h4
                dangerouslySetInnerHTML={{
                  __html: Constants.StepsDescription.step1.head,
                }}
              ></h4>
            </span>
            <span
              className="step_content"
              dangerouslySetInnerHTML={{
                __html: Constants.StepsDescription.step1.description,
              }}
            ></span>
          </div>
          <div className="cards_wrapp flex_center flex_row flex_hcenter flex_stretch">
            <Loader show={loadCalculate} />

            {!showResult ? (
              <div className="calculate_wrapp flex_col">
                <div className="calculate">
                  <h5>Calculate</h5>
                  <div className="flex_row flex_btw mD20">
                    <div className="flex_col input">
                      <label>From city</label>
                      <Select
                        onSelect={(i, data) => {
                          handleChangeCity(data, "from");
                        }}
                        loading={loadCities}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={cities}
                        value={cityFrom}
                      />
                    </div>
                    <img src={arrows} loading="lazy" width="35.2" height="30"/>
                    <div className="flex_col input">
                      <label>To city</label>
                      <Select
                        onSelect={(i, data) => {
                          handleChangeCity(data, "to");
                        }}
                        loading={loadCities}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={cities}
                        value={cityTo}
                      />
                    </div>
                  </div>

                  <div className="flex_row flex_btw mD20">
                    <div className="flex_col input">
                      <label>From ZIP</label>
                      <Select
                        placeholder={"choose city first..."}
                        onChange={(l, i) => {
                          setZIPFrom(i);
                        }}
                        loading={loadZIP}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={ZIPFrom}
                        options={ZIPFromOptions}
                      />
                    </div>
                    <img src={arrows} loading="lazy" width="35.2" height="30" />
                    <div className="flex_col input">
                      <label>To ZIP</label>
                      <Select
                        placeholder={"choose city first..."}
                        onChange={(l, i) => {
                          setZIPTo(i);
                        }}
                        loading={loadZIP}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={ZIPTo}
                        options={ZIPToOptions}
                      />
                    </div>
                  </div>

                  <label>Method</label>
                  <div className="methods flex_row mD20">
                    {methodTypes.map((i) => {
                      return (
                        <div
                          onClick={() => {
                            setMethod(i);
                          }}
                          className={`${
                            i.name.includes(method.name) && "active"
                          }`}
                        >
                          {i.name}
                        </div>
                      );
                    })}
                  </div>

                  <label>Car</label>
                  <div className="cars flex_row">
                    <Select
                      loading={loadCars}
                      placeholder="Select size of car"
                      onChange={(i, data) => {
                        handleChangeCar(data);
                      }}
                      options={cars}
                      value={car}
                    />
                  </div>
                </div>
                <div className="sumbit" onClick={calculate}>
                  Calculate
                </div>
              </div>
            ) : (
              <div className="calculate_wrapp flex_col">
                <div className="calculate h100">
                  <div
                    onClick={() => {
                      setShowResult(false);
                    }}
                    className="arrow_bk"
                  >
                    <img src={arrowBack} loading="lazy" width="35.2" height="30" />
                  </div>
                  <h5>Results</h5>
                  <div className="flex_col">
                    <div className="flex_row flex_btw flex_center mD20">
                      <div className="result_input">
                        <span>
                          {cityFrom.label}
                          {ZIPFrom ? ", " + ZIPFrom.label : ""}
                        </span>
                      </div>
                      <div className="distance">
                        <span>{Math.floor(totalRoad)} mi</span>
                      </div>
                      <div className="result_input">
                        <span>
                          {cityTo.label}
                          {ZIPTo ? ", " + ZIPTo.label : ""}
                        </span>
                      </div>
                    </div>
                    <label>Total price</label>
                    <div className="result_input">
                      <span>$ {Math.floor(totalPrice)}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="sumbit"
                  onClick={() => {
                    Anchor("step2");
                  }}
                >
                  Save and next
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="step_wrapp flex_row" id="step2">
          <div className="w50 flex_center flex_col">
            <span className="step_head">Stage 2</span>
            <span>
              <h4
                dangerouslySetInnerHTML={{
                  __html: Constants.StepsDescription.step2.head,
                }}
              ></h4>
            </span>
            <span
              className="step_content"
              dangerouslySetInnerHTML={{
                __html: Constants.StepsDescription.step2.description,
              }}
            ></span>
          </div>
          <div className="cards_wrapp flex_col flex_hcenter flex_stretch">
            <Loader show={loadSendMsg} />
            <div className="contact_form flex_col">
              <div className="title flex_center">
                <h5 className="w50">Contact us</h5>
              </div>

              <div className="form_item flex_col">
                <label>Name</label>
                <input
                  type="text"
                  className="w100"
                  onInput={(e) => {
                    setClientName(e.target.value);
                  }}
                />
              </div>

              <div className="form_item flex_col">
                <label>Phone</label>
                <input
                  type="text"
                  className="w100"
                  onInput={(e) => {
                    setClientPhone(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              value={"Call me"}
              className="w100 submit"
              onClick={sendContacts}
            >
              Call me
            </div>
          </div>
        </div>

        <div className="step_wrapp flex_row" id="step3">
          <div className="w50 flex_center flex_col">
            <span className="step_head">Stage 3</span>
            <span>
              <h4
                dangerouslySetInnerHTML={{
                  __html: Constants.StepsDescription.step3.head,
                }}
              ></h4>
            </span>
            <span
              className="step_content"
              dangerouslySetInnerHTML={{
                __html: Constants.StepsDescription.step3.description,
              }}
            ></span>
          </div>
          <div className="flex_center w50">
            <img src={shipment} loading="lazy" width="100%" height="100%"  />
          </div>
        </div>
      </div>

      <div className="wrapp_title flex_col flex_center">
        <h5>We work hard</h5>
        <h3>Professional and Punctual</h3>
      </div>
      <div id="statistic_wrapp" className="wrapper">
        <div className="container flex_row">
          <div className="image w50">
            <img src={statistic} loading="lazy"  width= "100%" height="100%" />
          </div>
          <div className="statistic w50 flex_col flex_around">
            <div className="row flex_row flex_vcenter flex_around">
              <h1>5+</h1>
              <h4>Years of experience</h4>
            </div>

            <div className="row flex_row flex_vcenter flex_around">
              <h1>1000+</h1>
              <h4>Satisfied customers</h4>
            </div>

            <div className="row flex_row flex_vcenter flex_around">
              <h1>30000+</h1>
              <h4>Cars transported</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapp_title flex_col flex_center" id="pricetable">
        <h5>Price table</h5>
        <h3>Average cost</h3>
      </div>
      <div id="table_wrapp" className="wrapper">
        <div className="container flex_col flex_center">
          <div className="head flex_end">
            <div className="col">
              <h4>Open carrier</h4>
            </div>
            <div className="col">
              <h4>Closed carrier</h4>
            </div>
          </div>

          <div className="row flex_row">
            <div className="col rounded">Less than 300 miles</div>
            <div className="col">
              <h4>$ 180</h4>
            </div>
            <div className="col">
              <h4>$ 225</h4>
            </div>
          </div>

          <div className="row flex_row">
            <div className="col">Between 300-1000 miles</div>
            <div className="col">
              <h4>$ 540</h4>
            </div>
            <div className="col">
              <h4>$ 720</h4>
            </div>
          </div>

          <div className="row flex_row">
            <div className="col">Over 1000 miles</div>
            <div className="col">
              <h4>$ 910</h4>
            </div>
            <div className="col">
              <h4>$ 1300</h4>
            </div>
          </div>
        </div>
      </div>

      <div id="footer_wrapp" className="wrapper">
        <div className="container flex_row flex_btw flex_vcenter">
          <div className="footer flex_col flex_center w100">
            <div className="w100 flex_center"><a href="/"><img className="logo" src={logo} loading="lazy"  width="100%" height="100%"/></a></div>
            <div className="flex_row footer_content w100 flex_hcenter">
              <div className="footer_col flex_btw flex_col contacts_wrapp">
                <div className="contact flex_row flex_vcenter">
                  <img src={location} loading="lazy" width="100%" height="100%" />
                  <div>
                    <span>Our address</span>
                    <div>
                      Illinois, Chicago 2301 w Glenlake ave apt 1 E, zip code
                      60659
                    </div>
                  </div>
                </div>
                <div className="contact flex_row flex_vcenter">
                  <img src={mail} loading="lazy" width="100%" height="100%"/>
                  <div>
                    <span>Send us email</span>
                    <div>kgtruckers@gmail.com</div>
                  </div>
                </div>
                <div className="contact flex_row flex_vcenter">
                  <img src={phone} loading="lazy" width="100%" height="100%" />
                  <div>
                    <span>Call us</span>
                    <div>+7-123-123-8888</div>
                  </div>
                </div>
              </div>

              <div className="footer_col flex_btw anchors flex_col">
                <span
                  onClick={() => {
                    Anchor("root");
                  }}
                >
                  Home
                </span>

                <span
                  onClick={() => {
                    Anchor("getstarted");
                  }}
                >
                  Get started
                </span>

                <span
                  onClick={() => {
                    Anchor("pricetable");
                  }}
                >
                  Price table
                </span>

                <span
                  onClick={() => {
                    Anchor("footer_wrapp");
                  }}
                >
                  Contacts
                </span>
              </div>

              <div className="footer_col flex_btw flex_start social_media flex_row">
                <a href="#">
                  <img src={tw} loading="lazy" width="28" height="28"/>
                </a>

                <a
                  href="https://www.instagram.com/kgtruckers/?igshid=YTQwZjQ0NmI0OA%3D%3D"
                  target="__blank"
                >
                  <img src={inst} loading="lazy" width="28" height="28"/>
                </a>

                <a href="#">
                  <img src={tg} loading="lazy" width="28" height="28" />
                </a>

                <a href="#">
                  <img src={fb} loading="lazy" width="28" height="28" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
