import React, { useState } from "react";
import Content from "../Content/Content";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import "./Main.scss";
const Main = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const switchMenu = (v) => {
    if (v) {
      setShowMobileMenu(false);
      document.body.style.overflow = "auto";
    } else {
      setShowMobileMenu(true);
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <div id="main_wrapper">
      <Header switchMenu={switchMenu} showMobileMenu={showMobileMenu}/>
      <Content switchMenu={switchMenu}/> 
    </div>
  );
};

export default Main;
